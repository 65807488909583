import {useSelector} from 'react-redux'
import {Helmet} from 'react-helmet'

export default function Camere() {

	const guests = useSelector(state => state.guests)

	if (guests.loading)
		return (
			<div className="text-center py-4">
				<div className="spinner-border"></div>
			</div>
		)
	
	let list = [
		{objectId: 'edi', cazare: true, nume: 'Eduard Duluman'},
		{objectId: 'adi', cazare: true, nume: 'Bercea Adrian'},
		...guests.list.filter(item => item.cazare)	
	]

	return (
		<>
			<Helmet>
				<title>Camere</title>
			</Helmet>
			<div className="list-group-flush">
				{list.map(item => (
					<div key={item.objectId} className="list-group-item list-group-item-action d-flex justify-content-between">
						<b>{item.nume.split(' ', 2)[0]} <span className="fw-normal text-muted">{item.nume.split(' ', 2)[1]}</span></b>
						{item.inainte &&
							<span className="text-muted">Ziua precedentă</span>
						}
					</div>
				))}
			</div>
		</>
	)
}