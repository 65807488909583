import {useSelector} from 'react-redux'
import ReactTooltip from 'react-tooltip'
import {Helmet} from 'react-helmet'

export default function Mese() {

	const guests = useSelector(state => state.guests)

	if (guests.loading)
		return (
			<div className="text-center py-4">
				<div className="spinner-border"></div>
			</div>
		)
	
	let mese = {}, 
		copii = [], 
		perMasa = {}

	let list = [
		{objectId: 'edi', masa: 'miri', nume: 'Eduard Duluman'},
		{objectId: 'adi', masa: 'miri', nume: 'Bercea Adrian'},
		...guests.list
	]

	list.forEach(item => {

		if (item.masa)
		{

			let numar = item.masa.toString()

			if (!mese.hasOwnProperty(numar)) mese[numar] = []
			if (!perMasa.hasOwnProperty(numar)) perMasa[numar] = 0
			
			// adauga persoane
			mese[numar].push(item)
			perMasa[numar] += item.persoane || 2

			// adauga copii
			if (item.copil)
			{
				if (!copii.hasOwnProperty(numar)) copii[numar] = 0
				copii[numar] += item.copil
			}

		}

	})

	const tables = (tables, className = 'masa') => tables
		.map(numar => numar.toString())
		.filter(numar => mese.hasOwnProperty(numar))
		.map(numar =>
			<div key={numar} className={className} data-tip={mese[numar].map(guest => `${guest.nume}${guest.persoane ? ` (${guest.persoane} pers)` : ''}${guest.copil ? ' (1 copil)' : ''}`).join('<br>')}>
				<div className="numar">{numar}</div>
				<div className="info">
					{perMasa[numar]}{copii[numar] ? `+${copii[numar]}` : ''} <i className="fad fa-users"></i>
				</div>
			</div>
		)

	return (
		<>

			<Helmet>
				<title>Mese</title>
			</Helmet>

			<div className="card-body plan">

				{/* STANGA */}
				<div>
					<div className="d-flex">
						{tables([5, 4, 3])}
					</div>
					<div className="d-flex justify-content-between align-items-center">
						<div>
							{tables([6, 7])}
						</div>
						{tables([1], 'masa mare')}
					</div>
					<div className="d-flex">
						{tables([8, 9, 10])}
					</div>
				</div>

				{/* MIJLOC */}
				<div className="d-flex flex-column justify-content-between align-items-center flex-grow-1">
					<div className="masa patrata">
						<div className="numar">FORMAȚIE</div>
					</div>
					<div className="masa patrata mica">
						<div className="numar">MASĂ MIRI</div>
						<div className="info">
							{perMasa.miri} <i className="fad fa-users"></i>
						</div>
					</div>
				</div>

				{/* DREAPTA */}
				<div>
					<div className="d-flex">
						{tables([11, 12, 13])}
					</div>
					<div className="d-flex justify-content-between align-items-center">
						{tables([2], 'masa mare')}
						<div>
							{tables([14, 15])}
						</div>
					</div>
					<div className="d-flex">
						{tables([18, 17, 16])}
					</div>
				</div>

			</div>

			<ReactTooltip key={list.length} effect="solid" multiline />

		</>
	)
}