export const USER_LOADING       = 'USER_LOADING'
export const USER_LOGGED_IN 	  = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT 	  = 'USER_LOGGED_OUT'

export const GUESTS             = 'confirmari'
export const GUESTS_ADDED       = 'GUESTS_ADDED'
export const GUESTS_MODIFIED    = 'GUESTS_MODIFIED'
export const GUESTS_REMOVED     = 'GUESTS_REMOVED'
export const GUESTS_LOADING     = 'GUESTS_LOADING'

export const AMOUNTS            = 'sume'
export const AMOUNTS_LOADED     = 'AMOUNTS_LOADED'