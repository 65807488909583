import {useState} from 'react'
import {useSelector} from 'react-redux'
import {Helmet} from 'react-helmet'

export default function Sume() {

	const amounts = useSelector(state => state.amounts)

	const [sort, setSort] = useState({
		type: 'suma',
		order: 'desc'
	})

	const sortBy = column => {

		let ascending = sort.order === 'asc',
			sortFields = {
				name: 'nume',
				suma: 'suma'
			},
			field = sortFields[column]
			
		setSort({
			type: column, 
			order: ascending ? 'desc' : 'asc'
		})

		amounts.list.sort((a,b) => ascending 
			? (a[field] > b[field] ? -1 : 1) 
			: (a[field] < b[field] ? -1 : 1)
		)

	}

	if (amounts.loading)
		return (
			<div className="text-center py-4">
				<div className="spinner-border"></div>
			</div>
		)

	return (
		<>
			<Helmet>
				<title>Sume</title>
			</Helmet>
			<table className="table table-striped">
				<thead>
					<tr>
						<th onClick={() => sortBy('name')}>
							<i className={'fad fa-sort' + (sort.type === 'name' ? (sort.order === 'asc' ? '-up' : '-down') : '')}></i> Nume
						</th>
						<th className="text-end" onClick={() => sortBy('suma')}>
							<i className={'fad fa-sort' + (sort.type === 'suma' ? (sort.order === 'asc' ? '-up' : '-down') : '')}></i> Sumă
						</th>
					</tr>
				</thead>
				<tbody>
					{amounts.list.map(item => (
						<tr className="guest" key={item.objectId}>
							<td>
								<b>{item.nume.split(' ', 2)[0]}</b> <span className="text-muted">{item.nume.split(' ', 2)[1]}</span>
							</td>
							<td className="text-end fw-bold">{item.suma.toLocaleString()}</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	)
}