import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {logout} from '../../actions/user'

export default function Logout() {

  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => dispatch(logout(true)), 1000)
  }, [dispatch])
  
	return (
		<div className="container-fluid">
			<div className="text-center py-4 my-4">
				<div className="spinner-grow mb-3"></div>
				<br />
				Logging out
			</div>
		</div>
	)
}