import {
	db, 
	auth,
	serverTime 
} from '../utils/firebase'

import {
	GUESTS,
	GUESTS_ADDED,
	GUESTS_MODIFIED,
	GUESTS_REMOVED,
	GUESTS_LOADING,
	AMOUNTS,
	AMOUNTS_LOADED
} from '../constants'

const store = {
	guests: db.collection(GUESTS),
	amounts: db.collection(AMOUNTS)
}

export const fetchAmounts = () => dispatch => store.amounts
	.orderBy('suma', 'desc')
	.get()
	.then(snapshot => {

		if (snapshot.empty)
			return dispatch({type: AMOUNTS_LOADED})

		dispatch({
			type: AMOUNTS_LOADED,
			data: snapshot.docs.map(doc => doc.data())
		})

	})

export const fetchGuests = () => dispatch => store.guests
	.limit(200)
	.orderBy('nume')
	.onSnapshot(snap => {

		dispatch({
			type: GUESTS_LOADING,
			data: false
		})
		
		snap.docChanges().forEach(change => {

			let data = change.doc.data()
			
			if (data.hasOwnProperty('participa') && data.participa === false)
				return false
			
			if (change.type === 'added')
				return dispatch({
					type: GUESTS_ADDED,
					data: data
				})
				
			if (change.type === 'modified')
				return dispatch({
					type: GUESTS_MODIFIED,
					data: data
				})

			if (change.type === 'removed')
				return dispatch({
					type: GUESTS_REMOVED,
					data: data.objectId
				})

			
		})

	})

export const toggleStatus = item => async dispatch => {

	const {uid} = auth.currentUser || {}

	if (!uid)
		return false
	
	return store.guests
		.doc(item.objectId)
		.update({
			userId: uid, 
			venit: !!item.venit ? false : serverTime
		})
		.catch(err => alert(err.message))

}