import {
	GUESTS_ADDED,
	GUESTS_MODIFIED,
	GUESTS_REMOVED,
	GUESTS_LOADING
} from '../constants'

const initialState = {
	list: [],
	loading: true
}

export default function Guests(state = initialState, action) {
	switch (action.type) {
		case GUESTS_LOADING:
			return {
				...state,
				loading: action.data
			}
		case GUESTS_ADDED:
			return {
				...state,
				list: [...state.list, action.data]
			}
		case GUESTS_MODIFIED:
			return {
				...state,
				list: state.list.map(item => {
					if (item.objectId === action.data.objectId)
						return action.data

					return item					
				})
			}
		case GUESTS_REMOVED:
			return {
				...state,
				list: state.list.filter(item => item.objectId !== action.data)
			}
		default:
			return state
	}
}