import React, {useEffect} from 'react'
import {Provider, useDispatch} from 'react-redux'
import {Router, Route, Switch} from 'react-router-dom'
import {useSelector} from 'react-redux'
import history from './utils/history'
import ReactDOM from 'react-dom'
import store from './reducers'

import {fetchGuests} from './actions/guests'
import {detectLoginStatus} from './actions/user'

import Logout from './components/Logout'
import Login from './components/Login'
import Dashboard from './components'

const App = () => {

	const dispatch = useDispatch()

	const user = useSelector(state => state.user)

	useEffect(() => {
		dispatch(detectLoginStatus())
		dispatch(fetchGuests())
	}, [dispatch])

	return (
		<Switch>
			{user.objectId && <Route path="/logout" component={Logout} />}
			{!user.objectId && <Route path="/login" component={Login} />}
			<Route component={Dashboard} />
		</Switch>
	)
}

ReactDOM.render(
	<Router history={history}>
		<Provider store={store}>
			<App />
		</Provider>
	</Router>,
  document.getElementById('app')
)