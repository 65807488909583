import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"

firebase.initializeApp({
  apiKey: "AIzaSyDsIsZgKCok-kVBAFCE6CtK3jV-tbbbt8U",
  authDomain: "dulumanjs.firebaseapp.com",
  projectId: "dulumanjs",
  storageBucket: "dulumanjs.appspot.com",
  messagingSenderId: "1044873581484",
  appId: "1:1044873581484:web:b70ab9b030b9c6c4632009"
});

// export const auth = app.auth();

export const auth = firebase.auth()
export const db = firebase.firestore()
export const serverTime = firebase.firestore.FieldValue.serverTimestamp()