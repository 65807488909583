import {Switch, Route, Redirect, NavLink, Link} from 'react-router-dom'
import {useSelector} from 'react-redux'

import Invitati from './Invitati'
import Camere from './Camere'
import Mese from './Mese'
import Sume from './Sume'

export default function Home() {

	const user = useSelector(state => state.user)
	const guests = useSelector(state => state.guests)
	const amounts = useSelector(state => state.amounts)
	
	let persoane = 4, copii = 0, suma = amounts.list.reduce((total, invitat) => total += invitat.suma, 0)

	guests.list.forEach(item => {

		persoane += item.persoane || 2

		if (item.copil)
			copii += item.copil

	})
	
	return (
		<div className="container pt-3">
			<div className="card mb-3">

				<div className="card-header">
					<ul className="nav nav-tabs card-header-tabs">
						<li className="nav-item">
							<NavLink className="nav-link" to="/invitati" exact>
								<i className="fad fa-users me-1" /> {guests.loading
									? '...' 
									: <><b>{persoane}</b> {copii ? `+ ${copii}` : ''}</> 
								}
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/mese">
								<i className="fad fa-utensils" />
							</NavLink>
						</li>
						{user.objectId === 'ngpCjbmD7iSDyZuPDgQOos9q9Aw1' &&
							<>
								<li className="nav-item">
									<NavLink className="nav-link" to="/camere">
										<i className="fad fa-bed-empty" />
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink className="nav-link" to="/sume">
										<i className="fad fa-sack-dollar me-1" /> {amounts.loading ? '...' : <b>{suma.toLocaleString()}</b>}
									</NavLink>
								</li>
							</>
						}
						<div className="separator" />
						<div className="nav-item align-self-end pb-2">
							{!user.objectId &&
								<Link to="/login" className="btn btn-sm btn-success" disabled={user.loading}>
									{user.loading ? <div className="spinner-border spinner-border-sm" /> : <i className="fas fa-user" />}
								</Link>
							}
						</div>
					</ul>
				</div>

				<Switch>
					<Route path="/invitati" component={Invitati} />
					<Route path="/camere" component={Camere} />
					<Route path="/mese" component={Mese} />
					<Route path="/sume" component={Sume} />
					<Redirect to="/invitati" />
				</Switch>

			</div>
		</div>
	)
}