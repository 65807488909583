import {AMOUNTS_LOADED} from '../constants'

const initialState = {
	list: [],
	loading: true
}

export default function Guests(state = initialState, action) {
	switch (action.type) {
		case AMOUNTS_LOADED:
			return {
				list: action.data || [],
				loading: false
			}
		default:
			return state
	}
}