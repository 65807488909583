import {
	auth,
} from '../utils/firebase'

import {fetchAmounts} from './guests'

import {
	USER_LOADING,
	USER_LOGGED_IN,
	USER_LOGGED_OUT,
} from '../constants'

function loginFailed(message) {
	alert(message)
	return {
		type: USER_LOADING, 
		data: false
	}
}

export const detectLoginStatus = () => dispatch => {
	auth.onAuthStateChanged(account => {

		if (!account)
			return dispatch({type: USER_LOGGED_OUT})

		dispatch({
			type: USER_LOGGED_IN,
			data: {
				objectId: account.uid,
				email: account.email
			}
		})

		if (account.uid === 'ngpCjbmD7iSDyZuPDgQOos9q9Aw1')
			dispatch(fetchAmounts())
		
	})
}

export const login = (email, password) => dispatch => {

	email = email.trim()
	password = password.trim()

	if (!email.length || !password.length)
		return loginFailed('Toate campurile sunt necesare.')

	dispatch({
		type: USER_LOADING,
		data: true
	})

	auth.signInWithEmailAndPassword(email, password)
		.catch(({message}) => dispatch(loginFailed(message)))

}

export const logout = () => () => {
	auth.signOut().then(() => window.location.reload())
}