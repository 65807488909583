import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunk from 'redux-thunk'

import amounts from './amounts'
import guests from './guests'
import user from './user'

const reducers = {
	amounts,
	guests,
	user
}

export default createStore(
	combineReducers(reducers),
	applyMiddleware(thunk)
)