import {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {toggleStatus} from '../../actions/guests'
import {Helmet} from 'react-helmet'
import Fuse from 'fuse.js'

export default function Invitati() {

	const dispatch = useDispatch()

	const [filter, setFilter] = useState('')
	const user = useSelector(state => state.user)
	const guests = useSelector(state => state.guests)

	const [sort, setSort] = useState({
		type: 'name',
		order: 'asc'
	})

	const sortBy = column => {

		let ascending = sort.order === 'asc',
			sortFields = {
				name: 'nume',
				table: 'masa'
			},
			field = sortFields[column]
			
		setSort({
			type: column, 
			order: ascending ? 'desc' : 'asc'
		})

		guests.list.sort((a,b) => ascending 
			? (a[field] > b[field] ? -1 : 1) 
			: (a[field] < b[field] ? -1 : 1)
		)

	}

	const onToggle = invitat => {

		if (!user.objectId)
			return false

		if (!window.confirm(invitat.venit ? `Ștergi statusul pentru ${invitat.nume}?` : `${invitat.nume} va fi marcat ca ajuns, ești sigur(ă)?`))
			return false

		dispatch(toggleStatus(invitat))

	}

	const fuse = new Fuse(guests.list, {
		keys: ['nume']
	})
	
	const results = filter.length >= 2
		? fuse.search(filter).slice(0, 10)
		: guests.list.map(item => ({item: item}))

	if (guests.loading)
		return (
			<div className="text-center py-4">
				<div className="spinner-border"></div>
			</div>
		)

	return (
		<>
			<Helmet>
				<title>Invitați</title>
			</Helmet>
			<div className="p-2 pb-1">
				<div className="input-group">
					<input type="text" className="form-control" placeholder="Caută" value={filter} onChange={e => setFilter(e.target.value)} />
					<button className="btn btn-danger" onClick={() => setFilter('')} disabled={filter.length < 2}>&times;</button>
				</div>
			</div>
			<table className="table table-striped">
				<thead>
					<tr>
						<th onClick={() => sortBy('name')}>
							<i className={'fad fa-sort' + (sort.type === 'name' ? (sort.order === 'asc' ? '-up' : '-down') : '')}></i> Nume
						</th>
						<th className="text-end" onClick={() => sortBy('table')}>
							<i className={'fad fa-sort' + (sort.type === 'table' ? (sort.order === 'asc' ? '-up' : '-down') : '')}></i> Masă
						</th>
					</tr>
				</thead>
				<tbody>
					{results.map(({item}) => (
						<tr className={'guest' + (item.venit ? ' green' : '')} key={item.objectId} onClick={() => onToggle(item)}>
							<td>
								<b>{item.nume.split(' ', 2)[0]}</b> <span className="text-muted">{item.nume.split(' ', 2)[1]} {item.persoane ? `(${item['persoane']} pers)` : ''} {item['copil'] ? '(+1 copil)' : '' }</span>
							</td>
							<td className="text-end">{item.masa}</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	)
}