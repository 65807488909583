import {useState} from "react"
import {useDispatch, useSelector} from 'react-redux'
import {login} from '../../actions/user'

export default function Login() {

	const dispatch = useDispatch()
	
	const user = useSelector(state => state.user)

	const [state, changeState] = useState({
		email: '',
		password: ''
	})

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onSubmit = e => {

		e.preventDefault()

		if (user.loading)
			return false
		
		dispatch(login(state.email, state.password))

	}

	return (
		<div className="container pt-3">
			<div className="row">
				<div className="col-12 col-sm-8 col-md-6 offset-sm-2 offset-md-3">
					<div className="card bg-light">
						<div className="card-body">
							<form onSubmit={onSubmit}>
								<input 
									type="email" 
									placeholder="Email"
									className="form-control mb-3" 
									onChange={e => setState({email: e.target.value.toLowerCase()})}
								/>
								<input 
									type="password" 
									placeholder="Parola" 
									className="form-control mb-3" 
									onChange={e => setState({password: e.target.value})}
								/>
								<button type="submit" className="btn w-100 btn-primary" disabled={user.loading}>
									{user.loading ? <div className="spinner-border spinner-border-sm" /> : 'Login'}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}